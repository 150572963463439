* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    background: linear-gradient(135deg, #4169E1, #6A5ACD) !important; /* Added !important to overwrite other styles */
    color: #333;
    flex-direction: column;
}

.main-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.container {
    text-align: center;
    max-width: 400px;
    padding: 30px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(110, 104, 104, 0.2);
}

h1 {
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
}

p {
    color: white;
    font-size: 18px;
}

.qrCode-text {
    color: white;
    font-size: 16px;
    margin-bottom: 10px;
}

.advice-text {
    color: #ddd;
    font-size: 14px;
    margin-bottom: 10px;
}

input[type="text"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s;
}

input[type="text"]:focus {
    border-color: #4169E1;
    outline: none;
}

button {
    height: 50px;
    width: 80px;
    #padding: 30px 25px;
    border: none;
    background-color: #6A5ACD;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

button:hover {
    background-color: #c1bccc;
    transform: translateY(-2px);
}

#result {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.valid {
    color: green;
}

.invalid {
    color: red;
}

.checking {
    color: #efefef;
}

footer {
    margin-top: 20px;
    font-size: 14px;
    color: #a19090;
}

footer a {
    color: #B0C4DE;
    text-decoration: none;
    font-weight: bold;
}

footer a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Estilos para o histórico em um bloco separado */
.history-card {
    width: 250px;
    max-height: 300px;
    overflow-y: auto;
    background: #f8f8f8;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.history-card h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.history-card ul {
    list-style-type: none;
    padding-left: 0;
    font-size: 14px;
}

.history-card li {
    padding: 8px;
    color: #666;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
    word-wrap: break-word;
}

.history-card li:last-child {
    border-bottom: none;
}